<template>
  <b-card>
    <template #header>
      <h6 class="mb-0">Edit channel</h6>
    </template>
    <b-card-text>
      <div class="content">
        <FormTemplate v-if="form" :form="form">
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <label class="label-for-xl" for="inputName">Name</label>
                        <b-form-input
                            type="text" id="inputName" class="form-control form-control-xl"
                            placeholder="enter widget name"
                            v-model="form.data.name"
                            :state="form.states.name"
                        />
                        <b-form-invalid-feedback v-if="form.errors">{{ form.errors.name }}</b-form-invalid-feedback>
                    </div>
                </div>
            </div>
        </FormTemplate>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';
export default {
    props: ['channel'],
    components: {
      FormTemplate
    },
    data(){
        return {
          form: null,
        };
    },

    mounted(){
      this.$emit('set-channel',{activeSidebar: "manageChannel"});
      this.form = new Form({
          name: this.channel ? this.channel.name : "",
      },{
          onSubmit: (form) => {
            return this.$api.put(`channel/${this.channel.id}`, {
                data: {name: form.get('name')}
            });
          },
          onSuccess: (form) => {
            let channels = this.$store.getters['Callcenter/getChannels'];
            let ch = channels.find(o => o.id === this.channel.id);
            ch.name = form.get('name');
            
            this.$store.dispatch('Callcenter/addChannels', channels);
          },
          onError: function(form, err){
              console.log(err);
          }
      });
    }
};
</script>
